<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="Brand Name" prop="name">
                <a-input v-model="queryParam.name" placeholder="" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Business Type" prop="businessType">
                <a-select v-model="queryParam.businessType" placeholder="-All Business Type-">
                  <a-select-option :key="item.id" v-for="(item,index) in BusinessTypeList">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />Search</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />Clear All</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['cigarette:brand:add']">
          <a-icon type="plus" />Add
        </a-button>
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['cigarette:brand:edit']">-->
<!--          <a-icon type="edit" />Modify-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['cigarette:brand:remove']">-->
<!--          <a-icon type="delete" />Delete-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['cigarette:brand:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->

      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['cigarette:brand:edit']" />-->
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['cigarette:brand:edit']">
            <a-icon type="edit" />Modify
          </a>
          <a-divider type="vertical" v-hasPermi="['cigarette:brand:audit']" v-if="record.status == 0" />
          <a @click="auditModal(record)" v-hasPermi="['cigarette:brand:audit']" v-if="record.status == 0" >
            <a-icon type="audit" />Audit
<!--                <a-icon type="play-circle" theme="twoTone" @click.stop="changeStatus(item,1)" v-if="item.status === 0" style="font-size: 28px;" />-->
<!--                      <a-icon type="pause-circle" theme="twoTone" @click.stop="changeStatus(item,0)" v-else style="font-size: 28px;" />-->
          </a>
          <a-divider type="vertical" v-hasPermi="['cigarette:brandSku:look']" v-if="record.status == 1 && record.brandStatus == 1" />
          <a @click="$refs.brandSku.show(record)" v-hasPermi="['cigarette:brandSku:look']"  v-if="record.status == 1 && record.brandStatus == 1">
             <a-icon type="eye" />Sku List
          </a>

<!--          <a-divider type="vertical" v-hasPermi="['cigarette:brand:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['cigarette:brand:remove']">-->
<!--            <a-icon type="delete" />Delete-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `${total} results`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />

      <BrandSku ref="brandSku" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageBrand, listBrand, delBrand, updateBrand} from '@/api/cigarette/brand'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {listConfig} from "@/api/cigarette/config";
import BrandSku from "../sku/popIndex"
export default {
  name: 'Brand',
  components: {
    CreateForm,
    BrandSku
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        logo: null,
        introduction: null,
        name: null,
        businessType: null,
        businessTypeName: null,
        socialMediaUrl: null,
        brandOfficalWebsite: null,
        status: null,
        reason: null,
        userId: null,
        brandStatus: null,
        pageNum: 1,
        pageSize: 10
      },
      BusinessTypeList:[],
      columns: [
        // {
        //   title: '${comment}',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Brand Name',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: 'Business Type',
          dataIndex: 'businessTypeName',
          align: 'center'
        },
        {
          title: 'Social Media Url',
          dataIndex: 'socialMediaUrl',
          align: 'center'
        },
        {
          title: 'Brand Official Website',
          dataIndex: 'brandOfficalWebsite',
          align: 'center'
        },

        {
          title: 'Brand Introduction',
          dataIndex: 'introduction',
          align: 'center'
        },
        {
          title: 'Brand Logo',
          dataIndex: 'logo',
          align: 'center'
        },

        // {
        //   title: '业务类型关联{zb_public_config.id}',
        //   dataIndex: 'businessType',
        //   ellipsis: true,
        //   align: 'center'
        // },

        // {
        //   title: 'Status',
        //   dataIndex: 'status',
        //   align: 'center'
        // },
        // {
        //   title: '审核原因',
        //   dataIndex: 'reason',
        //   align: 'center'
        // },
        // {
        //   title: '用户id 关联{zb_user.id}',
        //   dataIndex: 'userId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Status',
          dataIndex: 'brandStatus',
          align: 'center',
          customRender(t,r) {
            if(t == 1 && r.status == 1) {
              return 'Enable';//一次性
            } else if(t==0 && r.status == 1) {
              return 'Disable';//电池
            } else if(r.status == 0) {
              return 'Pending Review';//电池
            }else if(r.status == 2) {
              return 'Disable';//电池
            }
          }
        },
        {
          title: 'Operation',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
    this.getListBusinessType();
  },
  computed: {
  },
  watch: {
  },
  methods: {
    getListBusinessType () {
      listConfig({
        type: 0,
        status: 1
      }).then(res => {
        if (res.success) {
          this.BusinessTypeList = res.data;
        }
      })
    },
    /** 查询新-用户品牌列表 */
    getList () {
      this.loading = true
     pageBrand(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        logo: undefined,
        introduction: undefined,
        name: undefined,
        businessType: undefined,
        businessTypeName: undefined,
        socialMediaUrl: undefined,
        brandOfficalWebsite: undefined,
        status: undefined,
        reason: undefined,
        userId: undefined,
        brandStatus: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    auditModal(record) {
      let that = this;
      this.$confirm({
        title: 'Are sure to Pass this Brand?',
        content: record.name,
        okText: "Yes",
        cancelText: "No",
        onOk () {
          let updateBrandData = JSON.parse(JSON.stringify(record));
          updateBrandData.status = 1;
          updateBrandData.brandStatus = 1;
          return updateBrand(updateBrandData)
            .then(() => {
              that.getList()
            })
        },
        onCancel () {
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delBrand(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('cigarette/user-brand/export', {
            ...that.queryParam
          }, `新-用户品牌_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
